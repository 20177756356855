import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { navigate } from "gatsby"
import { Row, Col, Button, Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Homehero = () => {
  return (
    <Row
      className="g-0 p-2"
      style={{
        background: "linear-gradient(180deg,#f7f6fc00,#1b62c2)",
      }}
    >
      <Col lg={12} xl={6}>
        <Row className="g-0">
          <Col className="text-center" style={{ "z-index": "99" }}>
            <H1Styled className="display-2">FBA Management System</H1Styled>
            <Inset className="display-6">Made by Sellers for Sellers</Inset>
            <Inset className="display-6">Built to Grow</Inset>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <Button
              variant="primary"
              size="lg"
              active
              onClick={() => {
                navigate("/test")
              }}
            >
              Learn More
            </Button>
            <div>&nbsp;</div>
          </Col>
        </Row>
      </Col>

      <Col lg={12} xl={6} className="text-center">
        <Carousel>
          <Carousel.Item>
            <StaticImage
              src="../../assets/images/screen.png"
              alt=""
              placeholder="tracedSVG"
              layout="constrained"
              width={700}
              overflow="visible"
              class="d-block w-100"
            ></StaticImage>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../../assets/images/build.jpg"
              alt=""
              placeholder="tracedSVG"
              layout="constrained"
              width={700}
              overflow="visible"
              class="d-block w-100"
            ></StaticImage>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <StaticImage
              src="../../assets/images/screen.png"
              alt=""
              placeholder="tracedSVG"
              layout="constrained"
              width={700}
              overflow="visible"
              class="d-block w-100"
            ></StaticImage>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  )
}

const Inset = styled.h5`
  color: #444444;
  text-shadow: -1px -1px 1px #607d8b, -1px -1px 1px #ccc;
  font-weight: 400;
`

const H1Styled = styled.h1`
  font-variant: unicase;
  color: white;
  text-shadow: -1px -1px 5px #607d8b, -1px -1px 5px #ccc;
  font-weight: 800;
  line-height: 1em;
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "../../assets/images/screen.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "../../assets/images/build.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "../../assets/images/screen.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Homehero
